import { uuidv4 } from "./math";

export const getExtension = (fileName: string) => {
  const idx = fileName.lastIndexOf(".");
  return fileName.slice(idx + 1);
};

export const getDbBaseUrl = () => {
  return process.env.NEXT_PUBLIC_SUPABASE_URL || "";
};

export const getAvatarPath = (fileName: string, size = 250) => {
  size = parseInt(size.toString());

  return (
    getDbBaseUrl() +
    `/storage/v1/render/image/public/avatars/${fileName}?width=${
      2 * size
    }&height=${2 * size}`
  );
};

export const getJobsOrgPhotoPath = (fileName: string, size = 250) => {
  size = parseInt(size.toString());

  return (
    getDbBaseUrl() +
    `/storage/v1/render/image/public/jobs/${fileName}?width=${
      2 * size
    }&height=${2 * size}`
  );
};

export const getPortfolioItemPath = (fileName: string) => {
  return getDbBaseUrl() + `/storage/v1/object/public/portfolio/${fileName}`;
};

export const getPostMediaItemPath = (fileName: string) => {
  return getDbBaseUrl() + `/storage/v1/object/public/post-media/${fileName}`;
};

export const getDocumentPath = (fileName: string) => {
  return getDbBaseUrl() + `/storage/v1/object/public/document/${fileName}`;
};


export const getFilePath = (fileName: string) => {
  return getDbBaseUrl() + `/storage/v1/object/public/file/${fileName}`;
};


export const generateUuidFileName = (fileName: string) => {
  const extension = getExtension(fileName);
  const uuid = uuidv4();
  return `${uuid}.${extension}`;
};
